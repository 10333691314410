import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import Metadata from "../components/Metadata"
import FooterPitch from "../components/FooterPitch"

import OliverSig from "../images/oliver_signature.png"
import CelesteMain from "../images/a-brand-that-moves/study.jpg"
import ProgrammeAsName from "../images/a-brand-that-moves/designing_programmes_without_bg.png"
import FarmersCarry from "../images/a-brand-that-moves/farmers_carry.jpg"
import Type1 from "../images/a-brand-that-moves/diatype_regular.png"
import Type2 from "../images/a-brand-that-moves/diatype_mono_regular.png"
import Type3 from "../images/a-brand-that-moves/diatype_medium.png"
import MuybridgeWalk from "../images/a-brand-that-moves/muybridge_walking_man.gif"
import ProgrammeLogo from "../images/a-brand-that-moves/programme_logo.mp4"
import ProgrammeKerning from "../images/a-brand-that-moves/programme_kerning.mp4"
import CompositionAsShape from "../images/a-brand-that-moves/composition_as_shape.png"
import CompositionAsGrid from "../images/a-brand-that-moves/composition_as_grid.png"
import Color from "../images/a-brand-that-moves/color.png"
import MarkRef from "../images/a-brand-that-moves/mark_ref.jpg"
import CapitalGrid from "../images/a-brand-that-moves/capital_grid.gif"
import Mark1 from "../images/a-brand-that-moves/mark_1.png"
import Mark2 from "../images/a-brand-that-moves/mark_2.png"
import Mark3 from "../images/a-brand-that-moves/mark_3.png"
import Mark4 from "../images/a-brand-that-moves/mark_4.png"
import Mark5 from "../images/a-brand-that-moves/mark_5.png"
import Mark6 from "../images/a-brand-that-moves/mark_6.png"
import AppIcon from "../images/a-brand-that-moves/app_icon.png"
import SocialIcon from "../images/a-brand-that-moves/social_icon.png"
import Sean1 from "../images/a-brand-that-moves/sean_jumping.jpg"
import Sean2 from "../images/a-brand-that-moves/sean_meadows.jpg"
import Sean3 from "../images/a-brand-that-moves/sean_running.jpg"
import Sean4 from "../images/a-brand-that-moves/sean_mountain.jpg"
import Oliver1 from "../images/a-brand-that-moves/oliver_apartment.jpg"
import Ryan1 from "../images/a-brand-that-moves/ryan_stretching.jpg"
import Ryan2 from "../images/a-brand-that-moves/ryan_kettlebell.jpg"
import Movement1 from "../images/a-brand-that-moves/movement_1.jpg"
import Movement2 from "../images/a-brand-that-moves/movement_2.jpg"

const About = () => {
  return (
    <>
      <Metadata
        title="Programme"
        description="Programme is a workout app that plans every workout for you – based on your progress, equipment and lifestyle."
        url="https://programme.app/"
      />
      <Container>
        <Body>
          <h1>A Brand That Moves</h1>
          <p>
            This webpage exists to explain the history of Programme, what we're
            trying to solve and what we stand for, while articulating the
            rationale behind each element of our brand identity system, composed
            of: logo, typography, composition, color and photography.
          </p>
          <video autoPlay muted playsInline loop>
            <source src={ProgrammeLogo} type="video/mp4" />
          </video>
          <h3>Reflecting on who we are</h3>
          <p>
            Programme is rooted in respect for the body and takes a holistic
            view on human health. We believe movement is a fundamental part of
            life, that's too often neglected and focused on how our bodies look
            rather than how they feel and perform. Programme offers practical,
            personal and sustainable training plans that help you create habits
            that respect the body and achieve life-long results, regardless of
            prior knowledge or experience.
          </p>
          <p>
            Any meaningful brand tells a story that inspires. This requires an
            understanding of why we are here. Although our product exists on
            modern devices, our offering is access to what is already innately
            human — movement. Our brand will tell the story of movement to
            inspire our customers to move again, and our product will make
            movement accessible to many.
          </p>
          <blockquote>
            <strong>"Life is movement; it is it's very essence"</strong> -
            Aristotle
          </blockquote>
          <img
            loading="lazy"
            alt="Behind the scenes from Programme's Study of Movement"
            src={CelesteMain}
          />
          <caption>
            Behind the scenes from Programme's Study of Movement
          </caption>
          <h3>Where it started</h3>
          <p>
            Currently, the best step you can take to improve your physical
            health is to engage a personal trainer. With the ability to see to
            your lifestyle, personal trainers can create customised training
            plans to match it, all while adapting to your progressions and your
            equally important regressions. If you compared someone who works
            with a personal trainer, to your everyday workout class go-er, you'd
            see the value direction has on your training.
          </p>
          <p>
            However, personal training doesn't come without it's fair share of
            problems, the common ones being high price-tags, dogmatic training
            styles, varying quality, and required localisation. When we began
            our work, we questioned what it would take to provide the same
            service at a more affordable price point, with accessible training
            styles, consistent high quality, and available from anywhere in the
            world. The only way to deliver something like this is with an
            intelligent system that could automate the entire decision stack of
            a personal trainer, inclusive of decisions made consciously based on
            logic and subconsciously based on intuition. And that's exactly what
            we're doing.
          </p>
          <img
            loading="lazy"
            alt="Farmer's Carry from Eadweard Muybridge's Studies of motion"
            src={FarmersCarry}
            style={{
              width: "100%",
              maxWidth: "100%",
              marginBlockStart: "2rem",
            }}
          />
          <caption>
            Farmer's Carry from Eadweards Muybridge's Studies of motion
          </caption>
          <h3>Programme as Name</h3>
          <p>
            At this stage of our conception, I was reading the design classic
            Designing Programmes (Programme entwerfen) by Swiss designer Karl
            Gerstner. Now considered a central influence in computational
            design, this book provides an introduction to Gerstner’s design
            methodolgy that suggests using a rule based system to guide all
            design decisions of any given design product. The book showcases how
            the system could be applied across varying mediums from typography
            to architecture, whilst inspiring its integration into many more.
            Let’s run through it in 3 different contexts:
            <ol>
              <li>
                If you were to design a font, instead of finding individual
                design solutions for each character, Gerstner suggests creating
                a design system that would create all the design solutions for
                each character in the font.
              </li>
              <li>
                As an architect, instead of designing each window of a building
                uniquely, use a rule based design system to approach every
                window.
              </li>
              <li>
                Or take me for example, designing an app's interface, instead of
                designing unique components for every screen of an app, create a
                design system that would become the framework for all screens
                and components.
              </li>
            </ol>
            This book had a huge influence on what became a ubiquitous approach
            in modern design and prior to this, most of the type forms we knew
            were designed with no system in place.
          </p>
          <p>
            The reason this resonates with us at Programme, is because it aligns
            so deeply with what we set out to solve. The fitness industry,
            especially in the digital context, is fixated on offering individual
            workouts offered through vast libraries with no system in place. No
            system means no context, no context means no direction, wether it's
            a typeface built from individual characters or a training plan built
            from individual workouts. This is the problem Gerstner set out to
            solve with his design approach and the exact approach we are taking
            to build a holistic system for your well-being.
          </p>
          <p>
            Gerstner referred to these systems as programmes and proposed them
            as solutions. Not only did this approach become the solution to
            achieve our described goal, it also inspired our brand's name,
            Programme.
          </p>
          <img
            alt="Designing Programmes - Karl Gerstner"
            src={ProgrammeAsName}
            loading="lazy"
          />
          <blockquote>
            "Instead of solutions for problems, programmes for solutions" - Karl
            Gerstner, 1964
          </blockquote>
          <p>
            Designing Programmes and Gerstner’s approach became a key reference
            for our brand and design philosophy.
          </p>
          <h3>Typography</h3>
          <p>
            Having settled on our name, I now had to decide how to display it.
            The immediate connection was to work with Gerstner's typographic
            works, and more specifically his own implementation of the
            programmatic design approach discussed in Designing Programmes. He
            used this to create Gerstner-Programm when bringing Akzidenz Grotesk
            into the computational era, which at the time, stood beside
            Helvetica (known then as Neue Haas Grotesk) & Univers, the most
            prevalent san-serif typefaces of the 60's.
          </p>
          <p>
            Gerstner believed in taking what came before him, re-interpreting it
            into his context, while subtly improving the original. Although
            Gerstner-Programm is not commercially available today, this same
            mentality was reciprocated by Swiss design firm & foundry, Dinamo,
            who took Gerstner-Programm and beautifully brought it into our
            modern world by improving it's readability on the digital screens we
            intend to use them on. Dinamo called this typeface Diatype, and it's
            our choice for our brand's typography & wordmark.
          </p>
          <p>
            Dinamo introduced this font in multiple weights (including variable)
            and developed semi-mono + mono versions alike. Our selection of
            typefaces is simple, we took 2 weights of Diatype, regular and
            medium, and one weight of Diatype Mono, in regular. These selections
            create a contempary feel that visually articulate the programme we
            built our product upon.
          </p>
          <img loading="lazy" alt="Diatype Regular" src={Type1} />
          <caption>Diatype Regular</caption>
          <img
            loading="lazy"
            alt="Diatype Mono Regular for our numbers"
            src={Type2}
          />
          <caption>Diatype Mono Regular for our numbers</caption>
          <img loading="lazy" alt="Diatype Medium" src={Type3} />
          <caption>Diatype Medium</caption>
          <h3>Composition</h3>
          <p>
            So far, we've covered the work of Gerstner and his inspiration for
            our brand name, approach and use of typography; but we've yet to
            cover our second key reference. As mentioned, Programme is a
            movement company and our use of movement is a fundamental pillar to
            our offering. With the importance of this subject, we looked back to
            the works of Eadweard Muybridge and his famous studies of motion.
            Muybridge used modern technology to capture the first photographs of
            movement dating back to 1877. These comprehensive studies stand as a
            key influence to what we're doing at Programme, and to pay homage to
            this work we used these images to guide our brand's compositional
            elements, instilling a rich history into our visual language.
          </p>
          <img
            loading="lazy"
            alt="Muybridge's first photographs of movement"
            src={MuybridgeWalk}
          />
          <caption>Muybridge's first photographs of movement</caption>
          <p>
            Brand elements are just tools for communicating a message (like your
            brand's values), and like any tool they should be simple to use.
            Take a logo as an example, they gain value over time through
            consistent usage. They hold, or store that value if they're able to
            successfully communicate a message through recognisability and past
            marketing efforts.
          </p>
          <p>
            Shape and grid became compositional design tools when reviewing the
            work of Eadweard Muybridge. In his earliest works, Muybridge left
            the classic film negative frame visible in his compositions. These
            shapes respresent frames of movement – a simple idea that can be
            used to visually articulate human movement. We also leverage the
            grids found throughout Muybridge's work to present these frames
            creating a consistent design language, making composition a useful
            tool to communicate our message.
          </p>
          <h3>Composition as Shape</h3>
          <img
            loading="lazy"
            alt="Composition As Shape"
            src={CompositionAsShape}
          />
          <h3>Composition as Grid</h3>
          <img
            loading="lazy"
            alt="Composition As Grid"
            src={CompositionAsGrid}
            style={{ maxWidth: "100%", width: "100%" }}
          />
          <h3>Programme as Word</h3>
          <p>
            Ofcourse, we would use Gerstner-Programm inspired Diatype to express
            our brands wordmark, pulling straight from the cover of Programme
            entwerfen (Designing Programmes) itself.
          </p>
          <video autoPlay muted playsInline loop>
            <source src={ProgrammeKerning} type="video/mp4" />
          </video>
          <h3>Programme as Mark</h3>
          <p>
            Our product is the marriage of our curation of movements and our
            programme. When creating our mark we wanted it to have these
            elements instilled within it, while creating something that's
            recognisable, simple, beautiful and aligns with the other elements
            in our brand system.
          </p>
          <p>
            Our composition represents our study of movement, so we use this
            element as the first building block for our mark.
          </p>
          <img
            alt="Brand Mark Reference"
            loading="lazy"
            src={MarkRef}
            style={{ marginBlockStart: 0 }}
          />
          <p>
            To express the idea of a system or programme with the mark I looked
            to Gerstner's grid discussed in Designing Programmes, initially
            developed for the german economics magazine – Capital.
          </p>
          <img alt="Capital Grid" src={CapitalGrid} loading="lazy" />
          <p>
            In the same way our product places movement within our system, we
            placed the Muybridge frames within Gerstner's grid system to create
            the structure of our mark. From here we simply removed a selection
            of frames to create the shape of a "P" and place the final mark back
            within the system to create balanced padding in our products app
            icon and on our social icons.
          </p>
          <div
            style={{
              display: "grid",
              gridGap: "10px 40px",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <div>
              <img loading="lazy" alt="Shapes within a grid" src={Mark1} />
              <caption>Placing our movements and programme</caption>
            </div>
            <div>
              <img
                loading="lazy"
                alt="Shapes within a grid to form a letter P"
                src={Mark2}
              />
              <caption>Formation of Programme's Mark forming a "P"</caption>
            </div>
            <div>
              <img
                loading="lazy"
                alt="Padding applied using the grid"
                src={Mark3}
              />
              <caption>Padding using 6*6 Grid</caption>
            </div>
            <div>
              <img
                loading="lazy"
                alt="Padding applied using another grid"
                src={Mark4}
              />
              <caption>Padding using 5*5 Grid</caption>
            </div>
            <div>
              <img loading="lazy" alt="Programme's app icon" src={Mark5} />
              <caption>Programme's App Icon</caption>
            </div>
            <div>
              <img loading="lazy" alt="Programmes social icon" src={Mark6} />
              <caption>Programme's Social Icon</caption>
            </div>
            <div>
              <img loading="lazy" alt="App Icon in context" src={AppIcon} />
              <caption>App Icon in Context</caption>
            </div>
            <div>
              <img
                loading="lazy"
                alt="Social Icon in context"
                src={SocialIcon}
              />
              <caption>Social Icon in Context</caption>
            </div>
          </div>
          <p>
            We use this as white on black to resemble the negative space of the
            frames of movement.
          </p>

          <h3>Programme as Logo</h3>
          <video autoPlay muted playsInline loop>
            <source src={ProgrammeLogo} type="video/mp4" />
          </video>

          <h3>Color</h3>
          <p>
            While we rely on contrast between black and white for accessible
            communication, we use shades of natural tones throughout our app's
            experience to create a sense of ease and calm.
          </p>
          <img loading="lazy" alt="Brand Colors" src={Color} />

          <h3>Photography</h3>
          <p>
            Our photography aims to inspire people to move again. We present
            movement in action and beauty to showcase a lifestyle made
            accessible through physical empowerment.
          </p>
          <img
            loading="lazy"
            alt="Strength training exercise with a kettlebell"
            src={Movement1}
          />
          <img loading="lazy" alt="A man jumping through the air" src={Sean3} />
          <img
            loading="lazy"
            alt="A man strength training in an apartment"
            src={Oliver1}
          />
          <img
            loading="lazy"
            alt="A man exercising outside in greenery"
            src={Sean2}
          />
          <img
            loading="lazy"
            alt="A man training with a kettlebell"
            src={Ryan2}
          />
          <img
            loading="lazy"
            alt="Strength training exercise with a barbell"
            src={Movement2}
          />
          <img loading="lazy" alt="A man stretching" src={Ryan1} />

          <h3>Moving Forward</h3>
          <p>
            A brand system is proven valuable through its usage, to express our
            story & message. If you've made it this far, thank you for reading.
            If you've been a while since you moved, here's a quote from Socrates
            that might change that:
          </p>
          <blockquote>
            "No citizen has a right to be an amateur in the matter of physical
            training… what a disgrace it is for a man to grow old without ever
            seeing the beauty and strength of which his body is capable." –
            Socrates
          </blockquote>
          <p>And more importantly to us:</p>
          <blockquote>
            "Surely a person of sense would submit to anything, like exercise,
            so as to obtain a well functioning mind and a pleasant, happy life"
            – Socrates
          </blockquote>
          <p
            style={{
              borderTop: "2px solid #171717",
              paddingTop: 8,
              marginBlockStart: "4rem",
            }}
          >
            This document was written by Oliver Thomas Klein at the time of
            developing Programme's brand identity. We believe it serves as
            insight into the brand's history and purpose, but more importantly,
            the brand's ethos.
            <img
              loading="lazy"
              alt="Author signature"
              src={OliverSig}
              style={{ display: "block", width: 180 }}
            />
          </p>
        </Body>
        <FooterPitch />
      </Container>
    </>
  )
}

const Container = styled.div`
  padding: 0 0px;
`

export const Body = styled.div`
  margin: 8em auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 680px) {
    margin: 6em auto;
  }

  > h1,
  > h2,
  > h3,
  > p,
  > caption,
  > ul,
  > ol,
  > li,
  > video,
  > img,
  > blockquote,
  > div {
    width: 640px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    color: var(--white);

    @media (max-width: 680px) {
      max-width: 100%;
      padding: 0 20px;
    }
  }

  img,
  video {
    margin-block-start: 1rem;
    border-radius: 1px;
  }

  caption {
    margin-block-start: 0.5rem;
    margin-block-end: 1rem;
  }

  strong {
    font-weight: medium;
  }

  blockquote {
    border-left: 3px solid var(--white);
    padding-left: 12px;
  }

  h1 {
    margin: 0;
  }

  p,
  caption,
  li {
    color: var(--light-grey);
  }

  a {
    color: var(--white);
    text-decoration: underline;
  }
`

About.Layout = Layout
export default About
