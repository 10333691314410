import React from "react"
import styled from "styled-components"
import Button from "./Button"
import { useGoal } from "gatsby-plugin-fathom"

const FooterPitch = ({ videoUrl, children }) => {
  const handleGoal = useGoal("OAUGRCJK")

  return (
    <Container>
      <div>
        <h1>
          <div>Programme is a workout app that plans every workout for you</div>
        </h1>

        <h4>
          Programme learns from your past workouts, training experience and
          available equipment to create your optimal workout plan that adapts to
          your progress.
        </h4>

        <Buttons>
          <Button
            accent
            to="https://apps.apple.com/us/app/programme/id1554594236/"
            onClick={() => handleGoal(1)}
          >
            Download on iOS
          </Button>
          <Button to="/" secondary>
            See how it works
          </Button>
        </Buttons>
      </div>

      <video playsInline autoPlay loop muted>
        <source
          src={
            videoUrl ||
            "https://p001.nyc3.digitaloceanspaces.com/site/Bruin_006.mp4"
          }
          type="video/mp4"
        />
      </video>
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0;
  background: black;
  display: flex;
  position: relative;
  overflow: hidden;

  > div {
    display: flex;
    background-color: rgba(0, 0, 0, 0.4);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0px 20px;
    z-index: 10;
  }

  h1,
  h4 {
    text-align: center;
    font-weight: normal;
    margin: 0;
    max-width: 840px;
  }

  h1 {
    color: var(--white);
    position: relative;
    line-height: 1.2;
  }

  h4 {
    padding: 0.5em 1em;
    width: 800px;
    max-width: 100%;
    color: var(--light-grey);
  }

  video {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    opacity: 0.4;
  }

  @media (max-width: 48em) {
    height: calc(100vh);
    width: calc(100vw);
    margin: 0;
    border-radius: 0;
    h4 {
      padding: 0.5em;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 36em) {
    flex-direction: column;
    align-items: center;

    & > a {
      margin-bottom: 1em;
    }
  }
`

export default FooterPitch
