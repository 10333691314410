import styled from "styled-components"
import { Link } from "gatsby"

const Button = styled(Link)`
  display: block;
  height: 48px;
  min-height: 48px;
  padding: 0 28px;
  transition: 0.3s all;
  border-radius: 28px;

  text-align: center;
  line-height: 44px;
  font-size: 20px;
  font-family: Diatype;
  font-weight: bold;
  letter-spacing: -0.03em;
  background: ${({ accent }) => (accent ? "var(--blue)" : "var(--white)")};
  border-color: ${({ accent }) => (accent ? "var(--blue)" : "var(--white)")};
  color: ${({ accent }) => (accent ? "var(--white)" : "var(--black)")};

  border-width: 2px;
  border-style: solid;
  margin-inline-start: 0.5em;
  margin-inline-end: 0.5em;

  &:hover {
    opacity: 0.8;
  }

  ${({ secondary }) =>
    secondary
      ? `
    background: transparent;
    color: var(--white);

    &:hover {
      background: var(--white);
      color var(--black) !important;
      opacity: 1;
    }
  `
      : ``}
`

export default Button
